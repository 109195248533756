/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RequestContainer, RequestCardWrapper, RequestCard } from '../styles';
import { withTranslation } from 'react-i18next';
import { acceptOrRejectFriendRequest } from '../../../redux/actions';
import { connect } from 'react-redux';
import { ImageUrl } from "../../../utils/constants";
import { NoData } from './styles';

class RequestReceived extends Component {
  constructor() {
    super();
    this.state = {
      userId: null,
      initiativeId: null,
      initiativeName: null
    };
  }

  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name
    })
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null
    })
  };

  acceptOrRejectRequest = (id, status) => {
    const { acceptOrRejectFriendRequest } = this.props;
    acceptOrRejectFriendRequest({ request_id: id, status: status });
  };

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  render() {
    const { friendRequests, t } = this.props;
    const {userId, initiativeId, initiativeName} =this.state
    return (
      <RequestContainer>
        {friendRequests && friendRequests.length > 0 ?
          <RequestCardWrapper>
            {friendRequests && friendRequests.length > 0 && friendRequests.map((request, index) =>
              (<RequestCard key={index} background="#FD7175">
                <div className="profile">
                  <img onError={this.fallBack} src={`${ImageUrl}/${request.profile_image}`} alt={"Profile"} />
                </div>
                <div className="info">
                  <div className="name">{t(request.fullname)}</div>
                  <div className="wellness-icon">
                    {request.wellness_interest && request.wellness_interest.length > 0 && request.wellness_interest.map((wellness, index) =>
                      (index < 5 && <div key={index}  onMouseEnter={() => this.onEnterMouse(request.id, wellness.interest_id, wellness.interest_name)} onMouseLeave={() => this.onLeaveMouse()}>
                        <img src={`${ImageUrl}/${wellness.interest_icon}`} />
                        {userId===request.id && initiativeId=== wellness.interest_id &&
                        <div className="hover-card">
                          <div> {t(initiativeName)}</div>
                        </div>}
                      </div>
                      ))}
                  </div>
                </div>
                <div className="last-card">
                  <div className="confirm" style={{cursor:'pointer'}} onClick={() => this.acceptOrRejectRequest(request.id, 'Accepted')}>{t("Confirm")}</div>
                  <div className="cancel" onClick={() => this.acceptOrRejectRequest(request.id, 'Rejected')}>{t("Cancel")}</div>
                </div>
              </RequestCard>))}
          </RequestCardWrapper> :
          <NoData>{t("No Request Received")}</NoData>}
      </RequestContainer>
    )
  }
}

RequestReceived.propTypes = {
  acceptOrRejectFriendRequest: PropTypes.func,
  friendRequests: PropTypes.array,
  t: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  acceptOrRejectFriendRequest: (acceptData) => dispatch(acceptOrRejectFriendRequest(acceptData)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(RequestReceived));
