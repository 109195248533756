/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardContainerV2 } from '../../PeopleGridViewV2/styles';
import _ from 'lodash';
import Waiting from '../../Waiting';
import { ImageUrl } from "../../../utils/constants";
import { Container, NoData, Backdrop, GridCard, NoBuddy } from './styles';
const RemoveBuddyPopup = React.lazy(() => import('./removeBuddyPopup'));
import { withTranslation } from 'react-i18next';
import CommonButton from '../../common/CommonButton/CommonButton';
import { connect } from 'react-redux';
import { getUserFriends } from '../../../redux/actions';

class MyBuddies extends Component {
  constructor() {
    super();
    this.state = {
      showRemoveBuddy: false,
      popupType: "buddies",
      name: null,
      friendId: null,
      showRemoveButton: false,
      userId: null,
      initiativeId: null,
      initiativeName: null
    };
  }

  componentDidMount() {
    const { userIdValue, getUserFriends, friendList } = this.props;
    _.isNull(friendList?.[0]) && getUserFriends(userIdValue);
  }

  componentDidUpdate() {
    const { userIdValue, getUserFriends, friendList } = this.props;
    if(_.isNull(friendList[0])){
      getUserFriends(userIdValue);
    }
  }

  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name
    })
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null
    })
  };

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  openCloseRemoveBuddyPopup = (name, id) => {
    this.setState({
      showRemoveBuddy: !this.state.showRemoveBuddy,
      name: name,
      friendId: id
    })
  };

  showRemoveButton = (id) => {
    this.setState({
      showRemoveButton: !this.state.showRemoveButton,
      friendId: id
    })
  };

  closeRemoveBuddyPopup = () => {
    this.setState({
      showRemoveBuddy: !this.state.showRemoveBuddy,
      name: null,
      friendId: null
    })
  };

  hideRemoveButton = () => {
    this.setState({
      showRemoveButton: false,
    })
  };

  render() {
    const { friendList, t, history, user } = this.props;
    const { showRemoveBuddy, popupType, friendId, name, showRemoveButton, userId, initiativeId, initiativeName } = this.state;
    if (_.isUndefined(friendList) || _.isNull(friendList)) {
      return <Waiting />
    }
    return (
      !_.isEmpty(friendList[0]) ?
        <Container>
          {showRemoveButton && <Backdrop>
            <div onClick={() => this.hideRemoveButton()} />
          </Backdrop>}
          {friendList && friendList.length > 0 && friendList.map((friend, index) => (
            <CardContainerV2 key={index} marginRight={(index + 1) % 4 === 0 ? "0px" : "25px"}>
              <GridCard>
                <div className="wellness-champion">
                  {friend.is_champion ? <div>{t("Wellness Champions")}</div> : ""}
                </div>
                <div className="menu-button">
                  <img src="/public/images/menu-dots.png" alt="menu_icon" onClick={() => this.showRemoveButton(friend.friend_id)} />
                </div>
                {showRemoveButton && friendId === friend.friend_id &&
                  <div className="remove-button">
                    <div onClick={() => this.openCloseRemoveBuddyPopup(friend.fullname, friend.friend_id)} >{t("Remove Buddy")}</div>
                  </div>}
                <div className="profile">
                  <div>
                    <img onError={this.fallBack} src={`${ImageUrl}/${friend.profile_image}`} />
                  </div>
                </div>

                <div className="name">
                  {t(friend.fullname)}
                </div>
                {friend.department ? <div className="department">
                  {t(friend.department)}
                </div> :
                  <div className="no-department">
                    NA
                  </div>}
                <div className="wellness-card">
                  {friend.interests && friend.interests.map((Wellness, index) => (
                    index < 5 &&
                    <div key={index} onMouseEnter={() => this.onEnterMouse(friend.friend_id, Wellness.interest_id, Wellness.interest_name)} onMouseLeave={() => this.onLeaveMouse()}>
                      <img src={`${ImageUrl}/${Wellness.interest_icon}`} />
                      {userId === friend.friend_id && initiativeId === Wellness.interest_id &&
                        <div className="hover-card">
                          <div> {t(initiativeName)}</div>
                        </div>}
                    </div>))}
                </div>
                <div className="mutual-buddies">
                  {friend.mutual_friends_details && friend.mutual_friends_details.length > 0 ?
                    <div>
                      <div className="text">{t("Mutual Buddy")}</div>
                      <div className="profile-image-card">
                        {friend.mutual_friends_details && friend.mutual_friends_details.length > 0 && friend.mutual_friends_details.map((mutual, index) =>
                          index < 2 &&
                          <img onError={this.fallBack} src={`${ImageUrl}/${mutual.profile_image}`} key={index} />
                        )}
                        {friend.mutual_friends_details && friend.mutual_friends_details.length > 2 &&
                          <div className="count">{friend.mutual_friends_details.length - 2}+</div>}
                      </div>
                    </div> :
                    <div> <div className="no-text">NA</div></div>}
                </div>
                <div className="button-cotainer">
                  {/* <RequestButton background="rgba(159,201,137,1)" cursor={"default"}>
                    {t("Buddies")}
                  </RequestButton> */}
                  <CommonButton
                    btnType={"square"}
                    styles={{cursor:'pointer',background:"rgba(159,201,137,1)"}}
                    title={t("Buddies")}
                  />
                </div>
              </GridCard>
            </CardContainerV2>))}
          {showRemoveBuddy && <RemoveBuddyPopup showPopup={showRemoveBuddy} onHide={this.closeRemoveBuddyPopup} popupType={popupType} friendId={friendId} name={name} />}
        </Container> :
        <NoBuddy>
          {this.props.history && this.props.history.location.pathname === '/profile' && <div className='greyCircle'></div>}
          {this.props.history && this.props.history.location.pathname === '/profile' && <div className='nobuddyInfo'>{this.props.t("Hey")} <b>{user.fname}</b>, {this.props.t("You don’t Have any Buddy In your Community")} <br/> {this.props.t("lets Grow Your communityConnect with like minded People You May Love to connect.")}</div>}
          {this.props.history && this.props.history.location.pathname === '/profile' && <button className='goTo-Community' onClick={() => history.push('/community')}>{this.props.t("Go to Community")}</button>}
        </NoBuddy>
    )
  }
}

MyBuddies.propTypes = {
  history: PropTypes.object.isRequired,
  friendList: PropTypes.array,
  t: PropTypes.func,
  user: PropTypes.object,
  userIdValue: PropTypes.number,
  getUserFriends: PropTypes.func
};

const mapStateToProps = (state) => ({
  friendList: state.social.friendList
});

const mapDispatchToProps = (dispatch) => ({
  getUserFriends: (id) => dispatch(getUserFriends(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyBuddies));
