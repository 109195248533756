/* eslint-disable no-unused-vars */
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Layout, Header } from './styles';
import { StyledTabs } from '../styles';
import { Tab } from "react-bootstrap";
import MyBuddies from './myBuddies';
import RequestReceived from './requestReceived';
import { withTranslation } from 'react-i18next';
import { getFriendRequests, getUserFriends, getSentRequestList } from '../../../redux/actions';
import _ from 'lodash';
import Waiting from '../../Waiting';
import { connect } from 'react-redux';
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';
import SkeletonLoder from '../../common/skeletonLoder';
const RequestSend = React.lazy(() => import('./sendRequest'));

class ManageBuddies extends Component {
  constructor() {
    super();
    this.state = {
      selectedTab: 1,
      showCancelRequest: false
    };
  }

  componentDidMount() {
    const { fetchFriendRequests, userId, getUserFriends, getSentRequestList } = this.props;
    fetchFriendRequests();
    getUserFriends(userId);
    getSentRequestList()
  }

  setActiveTab = (active) => {
    this.setState({ selectedTab: parseInt(active, 10) });
  }

  render() {
    const { friendRequests, friendList, sendRequestsData, t, userId } = this.props;
    const {selectedTab} = this.state;
    if (_.isUndefined(friendRequests) || _.isUndefined(friendList) || _.isNull(friendList) || _.isUndefined(sendRequestsData)) {
      return <div style={{width:'1246px',margin:'auto',marginBottom:'100px'}}>
        <SkeletonLoder width={"1246px"} height={"60px"} style={{margin:'25px 0px 10px 0px'}}/>
        <SkeletonLoder width={"1246px"} height={"70px"} style={{margin:'0px 0px 25px 0px'}}/>
        <div style={{width:'100%', display:'flex', gap:'25px',flexWrap:'wrap'}}>
          <SkeletonLoder width={"291px"} height={"330px"}/>
          <SkeletonLoder width={"291px"} height={"330px"}/>
          <SkeletonLoder width={"291px"} height={"330px"}/>
          <SkeletonLoder width={"291px"} height={"330px"}/>
          <SkeletonLoder width={"291px"} height={"330px"}/>
          <SkeletonLoder width={"291px"} height={"330px"}/>
          <SkeletonLoder width={"291px"} height={"330px"}/>
          <SkeletonLoder width={"291px"} height={"330px"}/>
        </div>
      </div>
    }
    
    return (
      <Layout>
        <div>
          <Header>
            <div>
              <LazyImage div={'profile'} src={ImageUrl + "/images/PeoplePage/People_Group_Img.png"} alt="people_icon" />
              <div className="count">
                {selectedTab===1?  (!_.isEmpty(friendList[0]) && friendList.length? `${friendList.length} ${t("Buddies")}` :`0 ${t("Buddies")}`) :selectedTab===2?t("Buddy Requests Received"):t("Buddy Requests Sent")}
              </div>
            </div>
          </Header>
          <StyledTabs defaultActiveKey={1} id="styledTabs" onSelect={this.setActiveTab} style={{ display: "flex", background:"white", padding:"20px 20px 20px 20px",marginBottom:"25px" }}>
            <Tab eventKey={1} title={t("My Buddies")} tabClassName="first-tab">
              {selectedTab == 1 ?
                <MyBuddies
                  friendList={friendList}
                  userIdValue={userId}
                /> : null
              }
            </Tab>
            <Tab eventKey={2} title={t("Received")} tabClassName="first-tab">
              {selectedTab === 2 ?
                <RequestReceived
                  friendRequests={friendRequests}
                /> : null
              }
            </Tab>
            <Tab eventKey={3} title={t("Sent")} tabClassName="first-tab">
              {selectedTab == 3 ?
                <Suspense fallback={<Waiting/>}>
                  <RequestSend
                    sendRequestsData={sendRequestsData}
                  /></Suspense> : null
              }
            </Tab>
          </StyledTabs>
        </div>
      </Layout>
    )
  }
}

ManageBuddies.propTypes = {
  history: PropTypes.object.isRequired,
  friendRequests: PropTypes.array,
  fetchFriendRequests: PropTypes.func,
  userId: PropTypes.number,
  getUserFriends: PropTypes.func,
  friendList: PropTypes.array,
  getSentRequestList: PropTypes.func,
  sendRequestsData: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  friendRequests: state.social.friendRequests,
  friendList: state.social.friendList,
  sendRequestsData: state.social.sendRequestsData
});

const mapDispatchToProps = (dispatch) => ({
  fetchFriendRequests: () => dispatch(getFriendRequests()),
  getUserFriends: (id) => dispatch(getUserFriends(id)),
  getSentRequestList: () => dispatch(getSentRequestList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ManageBuddies));
